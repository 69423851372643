import revive_payload_client_OCx2ypEaK8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HQJvlxndzD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BiKoldMMxy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_q2TKZXNdh8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_35xeiyShQu from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EoNc0vFMHA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_MeZLXzMw0k from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_6Vr1XJ0ZSf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_qyhbpacdsgpyqa2f5cbnxplf7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_OCx2ypEaK8,
  unhead_HQJvlxndzD,
  router_BiKoldMMxy,
  payload_client_q2TKZXNdh8,
  navigation_repaint_client_35xeiyShQu,
  check_outdated_build_client_EoNc0vFMHA,
  chunk_reload_client_MeZLXzMw0k,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6Vr1XJ0ZSf
]